<template>
    <a-card class="transaction-holder" v-if="transaction && transaction.id" >
        <div class="top-section">
            <div class="image-holder-box relative">
                <span  class="badge-offer tT badge-position-absolute" :class="classLookup(transaction.status).color">
                    <template v-if="transaction.status !== 'void'">
                        <a-tooltip overlayClassName="change-tooltip-color" :title="transaction.isManual ? 'Print & Sign Agreement' : 'DocuSign Integrated Agreement'">
                            <a-icon v-if="transaction.isManual" type="highlight" />
                            <a-icon v-else type="file-protect" />
                        </a-tooltip>
                    </template>
                    {{classLookup(transaction.status).text}}
                </span>
                <div @click="onEdit(transaction)" class="inside" :style="{ backgroundImage: 'url(' + `${transactionImage || require('@/assets/no-image.png')}` + ')', backgroundSize:'cover', 'pointer-events': loading ? 'none' : 'auto' }" />
				<a-checkbox :checked="selectedTransactions.includes(transaction.id)" @change="$emit('toggleSelect')" style="position:absolute; right: 10px; top:10px; border-color: var(--primary)" />
            </div>
        </div>

		<div @click="onEdit(transaction)">
        <a-row class="text-section">
            <a-col :span="22" style="padding: 0; min-height: 47px;">
                <h6  class="transaction-line">{{transactionName}}</h6>
                <div v-if="transaction.offer && transaction.offer.purchasePrice">${{formatPrice(transaction.offer.purchasePrice)}}</div>
            </a-col>
            <a-col :span="2" style="padding: 0;">
                <MoreActions :val="transaction" :items="moreActions" @action="roleOptions"/>
            </a-col>
            <a-col :span="24" style="min-height: 105px;">
                <div class="transaction-line">
                    {{transactionLine1}}
                        <span v-if="transaction.type === 'purchase'">-
                            <span v-if="!conditionalHTML || transaction.isSigned" class="p-1" :style="`background:#eee`">Offer: <strong>{{transaction.offerType.toUpperCase()}}</strong></span>
                            <span v-else class="p-1" :style="`background:#eee`" v-html="conditionalHTML"></span>
                        </span>
                </div>
                <div class="dF">
                    {{transaction.type === 'lease' ? 'Tenant' : 'Purchaser'}}(s):&nbsp;
                    <div style="font-weight: bold;">{{transactionPurchasers}}</div>
                </div>
                <div class="text-med-gray">
                    ID: {{transaction.id}}
                </div>

                <div class="text-med-gray">
                    Date Created: {{transactionDate}}
                </div>
            </a-col>
        </a-row>

        <div class="bottom-section dF" >
            <a-col class="dF" :span="8" style="padding: 0;">
                <template v-for="(user,userI) in owners">
                    <template v-if="userI < 3" >
                        <a-tooltip overlayClassName="change-tooltip-color" :key="`userUSER${userI}`">
                            <div slot="title">{{user && user.name}}</div>
                            <div @click.stop="goto('participants')" :key="userI" class="user-icon relative" :style="userI !== 0? 'margin-left: -7px; box-shadow:-2px 0 5px rgba(100,100,100,0.2);' : ''">
                                <a-avatar :src="user.image" v-if="user.image" />
                                <a-avatar v-else>{{user.initial}}</a-avatar>
                            </div>
                        </a-tooltip>
                    </template>
                </template>
            </a-col>
            <a-col :span="16" style="padding: 0; line-height: 33px; display: flex; justify-content: flex-end; font-size:22px;">
                 <a-tooltip overlayClassName="change-tooltip-color">
                    <div slot="title" v-if="hasUnfinished.checklist > 0">You have {{hasUnfinished.checklist? hasUnfinished.checklist : 'no' }} To-Dos.</div>
                    <a-badge :dot="hasUnfinished.checklist > 0" class="mr-2" :class="hasUnfinished.checklist? '' : 'text-light-gray'">
                    <a-icon type="check-square" @click.stop="goto('checklist')" style="font-size:22px;"/>
                </a-badge>
                </a-tooltip>

                 <a-tooltip overlayClassName="change-tooltip-color">
                    <div slot="title" v-if="hasUnfinished.envelopes > 0">You have {{hasUnfinished.envelopes? hasUnfinished.envelopes : 'no' }} pending documents.</div>
                    <a-badge :dot="hasUnfinished.envelopes > 0" class="mr-2" :class="hasUnfinished.envelopes? '' : 'text-light-gray'">
                        <a-icon type="file" @click.stop="goto('docs')" style="font-size:22px;"/>
                    </a-badge>
                </a-tooltip>

                <a-tooltip overlayClassName="change-tooltip-color">
                    <div slot="title" v-if="hasUnfinished.notes > 0">You have {{hasUnfinished.notes? hasUnfinished.notes : 'no' }} unread notes. </div>
                    <a-badge :dot="hasUnfinished.notes > 0" :class="hasUnfinished.notes? '' : 'text-light-gray'">
                        <a-icon type="message" style="font-size:22px;" @click.stop="goto('notes')" />
                    </a-badge>
                </a-tooltip>
            </a-col>
        </div>
		</div>
    </a-card>
</template>

<script>


import MoreActions from 'bh-mod/components/common/MoreActions'
import {formatNumber, formatDate,getInitial, diffDays} from 'bh-mod'
export default {
	components: { MoreActions },
	props: ['transaction', 'selectedTransactions'],
	watch: {

	},
	computed: {
		conditionalHTML() {
			let result = false
			if (this.transaction.offerType === 'conditional') {
				let { graceEndDate } = this.transaction
				if (!isNaN(graceEndDate) && graceEndDate > Date.now()) {
					let days = diffDays(graceEndDate)
					if (days >= 0) {
						days = days === 0 ? 'today' : `in ${days} day(s)`
					}
					result = `Offer ends ${days}`
				}
			}
			return result
		},
		moreActions() {
			let actions = [{ label: this.transaction.status === 'void' ? 'View' : 'Edit', value: 'edit', icon: 'edit' }]
			if (this.editingRights) {
				if (['void', 'completed'].includes(this.transaction.status)) {
					actions.push({ label: 'Archive', value: 'archive', icon: 'fa-archive' })
				}
				if (!['void', 'draft'].includes(this.transaction.status)) {
					actions.push({ label: 'Void Transaction', value: 'void', icon: 'close' })
				} else if (['draft'].includes(this.transaction.status)) {
					actions.push({ label: 'Delete', value: 'delete', icon: 'delete' })
				}
			}
			return actions
		},
		owners() {
			if (!this.transaction.owners) this.transaction.owners = []
			return this.transaction.owners.map(usr => {
				let { firstName = '', lastName = '' } = usr
				let fullName = `${firstName} ${lastName}`

				if (!fullName.trim()) return {
					initial: '??',
					name: 'Unkown Agent',
				}

				return {
					initial: getInitial(fullName),
					name: fullName,
					image: usr.avatar,
				}

			})
		},
		hasUnfinished() {

			let tr = this.transaction

			let { tasks, envelopes, notes } = tr
			if (!tasks) tasks = {}
			if (!envelopes) envelopes = {}
			if (!notes) notes = {}

			let checklist = Object.values(tasks).filter(x => {
				return !x.completed
			}).length

			return {
				checklist,
				envelopes: Object.values(envelopes).filter(env => env.status && env.status.toLowerCase() !== 'completed' && env.status.toLowerCase() !== 'voided').length,
				notes: Object.keys(notes).length,
			}
		},
		transactionDate() {
			return formatDate(this.transaction.createdAt)
		},
		transactionPurchasers() {
			if (!this.transaction.purchasers || !this.transaction.purchasers.length) return `No ${this.transaction.type === 'lease' ? 'Tenant' : 'Purchaser'}(s)`
			return this.transaction.purchasers.length < 3 ? this.transaction.purchasers.filter((x, xI) => xI < 2).map(x => `${x.firstName} ${x.lastName[0]}.`).join(', ') : `${this.transaction.purchasers.length} Purchasers`

		},
		transactionImage() {
			return (this.transaction.product && this.transaction.product.unit && this.transaction.product.unit.image) || ''

		},
		transactionLine1() {
			if (this.instance.productType == 'highrise') {
				if (this.transaction.product && this.transaction.product.floor && this.transaction.product.unit) {
					return `${this.transaction.product.floor.name} (#${this.transaction.product.unit.unitNumber})`
				} else if (this.transaction.product && this.transaction.product.unit) {
					return 'Unit: ' + (this.transaction.product.unit.name || 'N/A')
				} else {
					return 'Unit N/A'
				}
			} else {
				if (this.transaction.product && this.transaction.product.lot) {
					return `${this.transaction.product.lot.address && (this.transaction.product.lot.address + ' - ') || ''}Lot ${this.transaction.product.lot && this.transaction.product.lot.name}  (${this.transaction.product.unit && this.transaction.product.unit.unitGroup && this.transaction.product.unit.unitGroup.name} ${this.transaction.product.unit && this.transaction.product.unit.name} - ${this.transaction.product.unit && this.transaction.product.unit.unitGroup && this.transaction.product.unit.unitGroup.size}' ${this.transaction.product.unit && this.transaction.product.unit.unitGroup && this.transaction.product.unit.unitGroup.type})`
				} else if (this.transaction.product && this.transaction.product.unit) {
					return 'Unit: ' + (this.transaction.product.unit.name || 'N/A')
				} else {
					return 'Lot N/A'
				}
			}
		},
		transactionName() {
			if (this.instance.productType == 'highrise') {
				let { product = {}, purchasers = [] } = this.transaction
				let { floor = {} } = product
				let name = `Unit #${product.unit && product.unit.name || 'N/A'}`
				if (this.transaction.type === 'purchase') {
					if (purchasers.length === 1) name += ` - (Buyer: ${purchasers[0].fullName})`
					else name += ` - ${purchasers.length} Purchasers`
				} else {
					let { totalRent = 0 } = this.transaction.offer

					name += `- $${this.formatPrice(totalRent)}/mo`
				}

				return name
			} else {
				let { product = {}, purchasers = [] } = this.transaction
				let { lot = {} } = product
				let name = `Lot ${lot.name || 'N/A'}`
				if (lot.address) {
					name += ` - ${lot.address}`
				} else {
					if (purchasers.length === 1) name += ` - (Buyer: ${purchasers[0].fullName})`
					else name += ` - ${purchasers.length} Purchasers`

				}
				return name
			}
		},
		editingRights() {
			let canEdit = false
			if (this.$p >= 40) {
				return true
			} else {
				this.transaction.owners.forEach(x => {
					if (x.id == this.user.id) {
						canEdit = true
					}
				})
				return canEdit
			}
		},
		user() {
			return this.$store.state.user.user
		},
		instance() {
			return this.$store.state.instance
		}
	},
	data() {
		return {
			loading: false
		}
	},
	methods: {
		classLookup(st) {
			let lookup = {
				void: 'badge-red',
				created: 'bg-black text-white',
				pending: 'bg-black text-white',
				completed: 'badge-green',
				draft: 'bg-gray text-white',
			}
			let textLookup = {
				void: 'Voided',
				created: 'Pending'
			}
			return {
				color: lookup[st] || 'badge',
				text: textLookup[st] || st,
			}
		},
		goto(e) {
			this.$router.push({ name: 'editTransaction', params: { id: this.transaction.id, tab: e } })
		},
		formatPrice(x) {
			return formatNumber(parseFloat(x))
		},
		roleOptions(val) {
			let tr = this.transaction
			tr = JSON.parse(JSON.stringify(tr))
			let name = this.transactionName
			let asset = this.$store.state.instance.productType === 'lowrise' ? 'Siteplan Lot' : 'Condo Unit'
			if (val.key == 'edit') {
				this.onEdit(this.transaction)
			} else if (val.key == 'archive') {
				if (this.$p < 40) return this.$message.error('You do not have permission to archive this transaction')
				this.$emit('archive', val)
			} else if (val.key === 'sold') {

				tr.status = 'sold'
				this.$confirm({
					title: 'Mark Transaction as Sold',
					content: h => <div>Do you want to mark this transaction <b>({name})</b> as sold?<br />This will set the underlying <b>{asset}'s to <em class="text-red">Sold</em></b>.<p class="mt-2">Are you sure you want to continue?</p></div>,
					okText: 'Continue',
					okType: 'primary',
					cancelText: 'Cancel',
					centered: true,
					onOk: () => {
						this.$api.put(`/transactions/:instance/${tr.id}/sold`, { status: 'sold' }).then(({ data }) => {
							this.$store.commit('SET_PROP', { where: ['transactions', tr.id], what: data })
						}).catch((err) => {
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								this.$message.error(this.$err(err, 'Uh oh, something went wrong. Please try again or contact your admin'))
							}
						})
					},
					onCancel() {
						console.log('CANCEL')
					}
				})
			} else if (val.key === 'unsell') {
				tr.status = 'hold'
				this.$confirm({
					title: 'Unsell Transaction',
					content: h => <div>Do you want to unsell this transaction <b>({name})</b>? <br />This will set the underlying <b>{asset}'s status to <em>Hold</em></b>.<p class="mt-2">Are you sure you want to continue?</p></div>,
					okText: 'Continue',
					okType: 'primary',
					cancelText: 'Cancel',
					centered: true,
					onOk: () => {
						this.$api.put(`/transactions/:instance/${tr.id}/unsell`, { status: 'sold' }).then(({ data }) => {
							this.$store.commit('SET_PROP', { where: ['transactions', tr.id], what: data })
						}).catch((err) => {
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								this.$message.error(this.$err(err, 'Uh oh, something went wrong. Please try again or contact your admin'))
							}
						})
					},
					onCancel() {
						console.log('CANCEL')
					}
				})
			} else if (val.key === 'void') {

				if (this.$p < 40) return this.$message.error('You do not have permission to void this transaction')
				if (tr.isSigned || tr.offerType === 'conditional' || tr.status === 'completed') {
					let message = 'Cannot Void Conditional Transaction'
					let description = 'Consider creating a Notice of Mutual Release to void this transaction.'
					if (tr.isSigned) {
						message = 'Cannot Void Signed Transaction'
					}
					return this.$notification.error({
						message,
						description,
					})

				}

				val.key = 'voidConfirm'
				return this.roleOptions(val)


			} else if (val.key === 'voidConfirm') {

				let isComplete = tr.status === 'completed' || tr.isSigned
				let obj = {
					title: 'Void Transaction',
					// content: h => <div>Do you want to void this transaction<b>({name})</b>? <br/>This will void any documents that were previously signed and remove any supporting documents and also removes any restrictions from the underlying <b>{asset}</b> and revert it's status to <em>Available</em>.<p class="mt-2">Are you sure you want to continue?</p></div>,
					content: h => <div>Voiding this transaction will remove all supporting documents and will revert the unit status back to available. </div>,
					okText: 'YES, VOID',
					okType: 'danger',
					cancelText: 'Cancel',
					centered: true,
					onOk: () => {
						this.$store.commit('LOAD', true)
						this.$api.put(`/transactions/:instance/${tr.id}/void`).then(({ data }) => {
							this.$store.commit('SET_PROP', { where: ['transactions', tr.id], what: data })
							this.$store.dispatch("FETCH_TRANSACTIONS");
						}).catch((err) => {
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								this.$message.error(this.$err(err, 'Uh oh, something went wrong. Please try again or contact your admin'))
							}
						}).finally(() => this.$store.commit('LOAD', false))
					},
					onCancel() {
						console.log('CANCEL')
					}
				}
				if (isComplete) {
					obj.content = h => <div><p>Voiding this transaction will remove all supporting documents and will revert the unit status back to available.</p><p><strong>Remember to create an amendment to void the transaction before continuing.</strong></p> </div>
				}
				this.$confirm(obj)
			} else if (val.key === 'delete') {
				this.$confirm({
					title: 'Delete Draft Transaction',
					content: h => <div>Deleting this transaction will remove hold on lot/unit and will revert the lot/unit status back to available. </div>,
					okText: 'YES, DELETE',
					okType: 'danger',
					cancelText: 'Cancel',
					centered: true,
					onOk: () => {
						this.$store.commit('LOAD', true)
						this.$api.delete(`/transactions/:instance/${tr.id}/draft`).then(() => {
							this.$store.commit('SET_PROP', { where: ['transactions', tr.id], del: true })
							this.$store.dispatch("FETCH_TRANSACTIONS");
						}).catch((err) => {
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								this.$message.error(this.$err(err, 'Uh oh, something went wrong. Please try again or contact your admin'))
							}
						}).finally(() => this.$store.commit('LOAD', false))
					},
					onCancel() {
						console.log('CANCEL')
					}
				})
			}
		},

		onEdit(transaction) {
			if (this.loading) {
				return;
			}
			this.loading = true;
			if (transaction.status === 'draft') {
				this.$router.push(`/new?draft=${transaction.id}`)
			} else {
				this.$router.push(`/transaction/${transaction.id}`)
			}
		}
	},
	created() {
		if (!this.transaction.status) this.transaction.status = 'created'
	}
}
</script>

<style lang="scss">
    .badge-position-absolute{
        position: absolute;
        left:5px;
        top:5px;

    }
    .transaction-holder {
        width: 320px;
        box-shadow: 0px 2px 6px rgba(47, 83, 151, 0.1);
        cursor: pointer;
        .ant-card-body {
            padding: 15px 15px 0;
        }
    }
    .user-icon {
        border-radius: 30px;
        width: 30px;
        height: 30px;
        background-color: #F4F3F6;
        .inside {
            width: 30px;
            height: 30px;
            border-radius: 30px;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: 50%;
        }
    }
</style>

<style>
    .transaction-holder:hover{
		transition: all 0.2s;
		box-shadow: 0px 7px 20px rgba(47, 83, 151, 0.15);
    }
	.ant-checkbox-inner{
		border-color: var(--primary) !important;
	}
</style>

<style scoped>
    .transaction-line{
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .top-section {
        width: 290px;
        height: 180px;
        text-align: center;
    }
    .text-section {
        width: 290px;
        padding: 10px 0;
    }
    .bottom-section {
        width: 290px;
        height: 47px;
        padding: 7px 0;
    }
    .image-holder-box {
        width: 290px;
        height: 180px;
        background-color: #F4F3F6;
        margin-left: auto;
        margin-right: auto;
    }
    .image-holder-box .inside{
        height: 180px;
        width: 290px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: 50%;
    }
</style>
