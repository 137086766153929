<template>
	<div class="w-full dF fC f1 px-4 pb-4 hide-scrollbar" style="overflow-y: scroll; background:var(--light-bg-gray);">
		<div>
			<a-modal :visible="showArchive" @cancel="onClose" @ok="onClose" :footer="null"
				:bodyStyle="{ height: '230px', padding: '30px' }" width="520px" centered>

				<h5 class="mb-4">Archive Transaction</h5>

				<div>
					Would you like to add final notes before this opportunity is archived?
					<a-input v-model="archiveNote" class="mt-2" size="large" />
				</div>

				<div style="text-align: right;">
					<a-button @click="onClose" class="mt-4 mr-3" style="width: 125px;">CANCEL</a-button>
					<a-button @click="confirmArchive" style="width: 125px;" type="primary">ARCHIVE</a-button>
				</div>


			</a-modal>

			<BHLoading :show="loadingPage" />

			<template v-if="transactions.length">
				<!-- Active Tab -->
				<template v-if="transactions.length && transactionType.value !== 'archived'">
					<div>
						<div class="dF aC jSB pb-3" style="position:absolute; z-index: 10; background:var(--light-bg-gray); width:97%;">
							<div class="dF aC" style="gap: 25px;">
								<h4>{{ transactionType.label }}</h4>

								<a-select v-model="bulkAction" placeholder="Bulk Action" style="width: 175px;" class="text-primary" @select="onDownload" :allowClear="bulkAction !== 'Bulk Action'">
									<a-select-option value="all">Download All</a-select-option>
									<a-select-option value="pending">Download All Pending</a-select-option>
									<a-select-option value="completed">Download All Completed</a-select-option>
									<a-select-option value="void">Download All Voided</a-select-option>
									<a-select-option value="archived">Download All Archived</a-select-option>
									<a-select-option value="selected">Download Selected</a-select-option>
								</a-select>
							</div>
							<div class="dF aC" style="gap: 10px;">
								<a-pagination v-if="displayMode == 'list'" showLessItems :defaultCurrent="1" v-model="currentPage" :total="transactionsCount" show-size-changer @showSizeChange="onShowSizeChange"  :showTotal="(total, range) => `${range[0]}-${range[1]} of ${total} Transactions`" :pageSize="pageSize"  />

								<div v-if="displayMode == 'block'" class="mr-5">
									<a-select placeholder="Sort Transactions" style="width: 175px;" class="text-primary"
										v-model="sortBy" :dropdownMenuStyle="{
											'overflow-y': 'hidden',
											'max-height': 'max-content',
										}">

										<a-select-option v-for="(item, index) in sortOptions" :key="index"
											:value="item.value">
											{{ item.name }}
										</a-select-option>
									</a-select>
								</div>
								<i @click="changeDisplay('block'); $store.commit('SET_TRANSACTIONS_PAGE')"
									:style="displayMode == 'block' ? 'color:var(--orange)' : 'color:#D3D0D6'"
									style="cursor:pointer; font-size:25px" class="mr-3 fa fa-th" />
								<i @click="changeDisplay('list'); $store.commit('SET_TRANSACTIONS_PAGE')"
									:style="displayMode == 'list' ? 'color:var(--orange)' : 'color:#D3D0D6'"
									style="cursor: pointer; font-size:25px" class="fa fa-th-list" />
							</div>
						</div>
						<div v-if="filteredTransactions.length" class="pt-8">
							<div v-if="displayMode == 'block'">
								<a-row :gutter="20" type="flex" v-if="filteredTransactions.length">
									<a-col class="mb-4" v-for="(trans, transI) in filteredTransactions" :key="transI">
										<ActiveCards :transaction="trans" @edit="editTransaction"
											@archive="archiveTransaction" :selectedTransactions="selectedTransactions" @toggleSelect="toggleSelect(trans.id)"/>
									</a-col>
								</a-row>
								<div class="text-center" v-if="transactionsCount !== totalCurrentTransactions.length">
									<a-button v-if="loading" type="primary" ghost icon="loading" size="large">
										Loading...
									</a-button>
									<a-button v-else type="primary" ghost size="large" @click="currentPage = currentPage + 1">
										Load More
									</a-button>
								</div>
							</div>
							<div v-else-if="displayMode == 'list'">
								<a-table :rowKey="(e) => e.id" :columns="columns" :dataSource="filteredTransactions" :row-selection="rowSelection"
									:pagination="false" class="archived-table" :scroll="{ x: 1050 }" :loading="loading" @change="handleChange">
									<div slot="image" slot-scope="record">
										<img v-if="record.product && record.product.unit && record.product.unit.image"
											style="width: 100px; object-fit: contain;" :src="record.product.unit.image" />
									</div>
									<div slot="id" slot-scope="id">
										{{ id.id }}
									</div>
									<div slot="name" slot-scope="record">
										<span class="block" :class="record.status === 'void' ? 'text-danger' : ''">{{
											transactionName(record) }}</span>
									</div>
									<div slot="price" slot-scope="record">
										<span v-if="record.offer && record.offer.purchasePrice">${{
											formatPrice(record.offer.purchasePrice) }}</span>
									</div>
									<div slot="status" slot-scope="record">
										<span class="badge-offer tT" :class="classLookup(record.status).color">
											{{ classLookup(record.status).text }}
										</span>
									</div>
									<div slot="owner" slot-scope="owner" class="dF">
										<template v-for="(user, userI) in owner.owner">
											<div v-if="userI < 3" :key="userI" class="owner-icon"
												:style="userI !== 0 ? 'margin-left: -14px' : ''">
												<div class="inside"
													:style="{ 'background-image': 'url(' + `${user.avatar}` + ')' }" />
											</div>
										</template>
									</div>
									<div slot="participant" slot-scope="participant" class="dF">
										<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
											viewBox="0 0 14.554 14.554" style="margin-top: 5px;" fill="#9ea0a5">
											<path id="Icon_awesome-user-alt" data-name="Icon awesome-user-alt" class="cls-1"
												d="M7.277,8.187A4.093,4.093,0,1,0,3.184,4.093,4.094,4.094,0,0,0,7.277,8.187Zm3.638.91H9.349A4.948,4.948,0,0,1,5.2,9.1H3.638A3.638,3.638,0,0,0,0,12.735v.455a1.365,1.365,0,0,0,1.364,1.364H13.189a1.365,1.365,0,0,0,1.364-1.364v-.455A3.638,3.638,0,0,0,10.915,9.1Z" />
										</svg>
										<div class="ml-2" style="font-size: 20px;">{{ participant.participants.length || 0
										}}</div>
									</div>
									<div slot="purchaser" slot-scope="record" style="font-weight: bold;">
										{{ transactionPurchasers(record) }}
									</div>
									<div slot="created" slot-scope="record" style="color: #9EA0A5;">
										{{ formatDate(record.createdAt) }}
									</div>
									<div slot="end" slot-scope="record" style="color: #9EA0A5;">
										{{ formatDate(record.updatedAt) }}
									</div>
									<div slot="action" slot-scope="record">
										<MoreActions :val="record" :items="moreActions(record)"
											@action="(e) => roleOptions(e, record)" />
									</div>
								</a-table>
							</div>
						</div>
						<template v-else-if="!loading">
							<EmptyApp :app="$store.state.theApp" />
						</template>
					</div>
				</template>

				<template v-if="transactionType.value == 'archived'">
					<div class="dF aC jSB">
						<h4 class="mb-3 mt-3">Archived</h4>
						<a-pagination showLessItems :defaultCurrent="1" v-model="currentPage" :total="transactionsCount" show-size-changer @showSizeChange="onShowSizeChange"  :showTotal="(total, range) => `${range[0]}-${range[1]} of ${total} Transactions`" :pageSize="pageSize"  />
					</div>
					<a-table :rowKey="(e) => e.id" :columns="archiveColumns"
						:dataSource="transactions.filter(x => x.archived)" :pagination="false" class="archived-table"
						:scroll="{ x: 1050 }" :loading="loading" @change="handleChange">
						<div slot="image" slot-scope="record">
							<img v-if="record.product && record.product.unit && record.product.unit.image" style="width: 100px; object-fit: contain;" :src="record.product.unit.image"/>
						</div>
						<div slot="id" slot-scope="id">
							{{ id.id }}
						</div>
						<div slot="name" slot-scope="record">
							<span class="block" :class="record.status === 'void' ? 'text-danger' : ''">{{
								transactionName(record) }}</span>
							<strong v-if="record.status == 'draft'">DRAFT</strong>
							<strong v-if="record.status == 'void'">VOIDED</strong>
						</div>
						<div slot="note" slot-scope="record">
							{{ getArchiveNote(record) }}
						</div>
						<div slot="owner" slot-scope="owner" class="dF">
							<template v-for="(user, userI) in owner.owner">
								<div v-if="userI < 3" :key="userI" class="owner-icon"
									:style="userI !== 0 ? 'margin-left: -14px' : ''">
									<div class="inside" :style="{ 'background-image': 'url(' + `${user.avatar}` + ')' }" />
								</div>
							</template>
						</div>
						<div slot="participant" slot-scope="participant" class="dF">
							<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 14.554 14.554"
								style="margin-top: 5px;" fill="#9ea0a5">
								<path id="Icon_awesome-user-alt" data-name="Icon awesome-user-alt" class="cls-1"
									d="M7.277,8.187A4.093,4.093,0,1,0,3.184,4.093,4.094,4.094,0,0,0,7.277,8.187Zm3.638.91H9.349A4.948,4.948,0,0,1,5.2,9.1H3.638A3.638,3.638,0,0,0,0,12.735v.455a1.365,1.365,0,0,0,1.364,1.364H13.189a1.365,1.365,0,0,0,1.364-1.364v-.455A3.638,3.638,0,0,0,10.915,9.1Z" />
							</svg>
							<div class="ml-2" style="font-size: 20px;">{{ participant.participants.length || 0 }}</div>
						</div>
						<div slot="purchaser" slot-scope="record" style="font-weight: bold;">
							{{ transactionPurchasers(record) }}
						</div>
						<div slot="start" slot-scope="start" style="color: #9EA0A5;">
							{{ start.startDate }}
						</div>
						<div slot="end" slot-scope="record" style="color: #9EA0A5;">
							{{ formatDate(record.updatedAt) }}
						</div>
						<div slot="action" slot-scope="record">
							<div class="edit-delete" @click="editArchive(record)">
								<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"
									fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
									stroke-linejoin="round" class="feather feather-edit-2">
									<path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
								</svg>
							</div>
						</div>
					</a-table>
				</template>

			</template>
			<div style="background:var(--light-bg-gray)" v-else-if="!loading">
				<EmptyApp :app="$store.state.theApp" />
			</div>
		</div>
	</div>
</template>

<script>
import { formatDate, formatNumber } from 'bh-mod'
import BHLoading from 'bh-mod/components/common/Loading'
import EmptyApp from 'bh-mod/components/common/EmptyApp'
import ActiveCards from '@/components/common/activeCards'
import moment from "moment"
import MoreActions from 'bh-mod/components/common/MoreActions'

export default {
	components: {
		BHLoading, EmptyApp, ActiveCards, MoreActions
	},
	data() {
		return {
			loading: false,
			timeNow: Date.now(),
			mailOpen: '',
			baseImage: '',
			loadModal: false,
			taskOpen: '',
			appointmentOpen: '',
			searchMode: false,
			selectContactView: false,
			templateId: '',
			customer: '',
			showArchive: false,
			archiveNote: '',
			selectedTransaction: {},
			sortBy: 'createdAt:DESC',
			selectedTransactions:[],
			bulkAction: 'Bulk Action'
		}
	},
	watch: {
		currentPage() {
			this.getTransactions();
		},
		sortBy(newVal) {
			if(this.displayMode === 'block'){
				this.$store.commit('SET_TRANSACTIONS_PAGE', 1)
				this.$store.commit('EDIT_SORT_QUERY', newVal)
				this.getTransactions()
			}
		}
	},
	computed: {
		displayMode() {
			return this.$store.state.appData.display
		},
		columns() {
			return [
				{ title: 'Thumbnail', key: 'image', scopedSlots: { customRender: 'image' } },
				{ title: 'Name', key: 'product', scopedSlots: { customRender: 'name' }, sorter:true },
				{ title: 'Offer Price', key: 'price', scopedSlots: { customRender: 'price' }, sorter:true },
				{ title: 'Status', key: 'status', scopedSlots: { customRender: 'status' }, sorter:true },
				{ title: 'Purchaser / Tenant(s)', key: 'purchaser', scopedSlots: { customRender: 'purchaser' } },
				{ title: 'Date Created', key: 'createdAt', scopedSlots: { customRender: 'created' }, sorter:true },
				{ title: 'Last Activity', key: 'updatedAt', scopedSlots: { customRender: 'end' }, sorter:true },
				{ title: '', key: 'action', scopedSlots: { customRender: 'action' } },
			]
		},
		archiveColumns() {
			return [
				{ title: 'Thumbnail', key: 'image', scopedSlots: { customRender: 'image' } },
				{ title: 'Name', key: 'product', scopedSlots: { customRender: 'name' }, sorter:true },
				{ title: 'Purchaser/Tenant(s)', key: 'purchaser', scopedSlots: { customRender: 'purchaser' } },
				{ title: 'Archive Note', key: 'note', scopedSlots: { customRender: 'note' }, sorter:true },
				{ title: 'Last Activity', key: 'updatedAt', scopedSlots: { customRender: 'end' }, sorter:true },
				{ title: '', key: 'action', scopedSlots: { customRender: 'action' } },
			]
		},
		transactionsCount() {
			return this.$store.state.appData.transactionsCount;
		},
		currentPage:{
            get(){
                return this.$store.state.appData.currentPage
            },
            set(val){
                this.$store.commit('SET_TRANSACTIONS_PAGE', val)
            }
        },
		totalCurrentTransactions(){
			let transactions = this.activeTransactions
			if (this.transactionType.value !== 'all') {
				transactions = this.activeTransactions.filter(x => {
					return this.transactionType.value === 'created' ? ['created', 'pending'].includes(x.status) : this.transactionType.value === x.status
				})
			}

			return transactions;
		},
		pageSize:{
			get(){
                return this.$store.state.appData.pageSize
            },
            set(val){
                this.$store.commit('SET_PAGE_SIZE', val)
            }
        },
		archived() {
			return this.transactions.filter(x => x.archived)
		},
		activeTransactions() {
			return this.transactions.filter(x => !x.archived)
		},
		integrated() {
			return this.$store.state.appData.vendors.docusign
		},
		transactionType() {
			return this.$store.state.appData.transactionType
		},
		filter() {
			return this.$store.state.appData.filter
		},
		transactions() {
			return Object.values(this.$store.state.appData.transactions)
		},
		filteredTransactions() {
			let transactions = this.totalCurrentTransactions;

			if (this.sortBy) {
				transactions = transactions.sort((a, b) => {
					if (this.sortBy === 'createdAt:ASC') {
						return moment(a.createdAt).format("x") - moment(b.createdAt).format("x")
					} else if (this.sortBy === 'createdAt:DESC') {
						return moment(b.createdAt).format("x") - moment(a.createdAt).format("x")
					} else if (this.sortBy === 'price:ASC') {
						const aPrice = Number(a.offer && a.offer.purchasePrice || 0);
						const bPrice = Number(b.offer && b.offer.purchasePrice || 0);
						return aPrice - bPrice
					} else if (this.sortBy === 'price:DESC') {
						const aPrice = Number(a.offer && a.offer.purchasePrice || 0);
						const bPrice = Number(b.offer && b.offer.purchasePrice || 0);
						return bPrice - aPrice;
					} else if (this.sortBy === 'product:ASC') {
						if (this.instance.productType == 'highrise') {
							return Number(a.product && a.product.unit && a.product.unit.unitNumber || 0) - Number(b.product && b.product.unit && b.product.unit.unitNumber || 0)
						} else {
							return Number(a.product && a.product.lot && a.product.lot.name || 0) - Number(b.product && b.product.lot && b.product.lot.name || 0)
						}
					} else if (this.sortBy === 'product:DESC') {
						if (this.instance.productType == 'highrise') {
							return Number(b.product && b.product.unit && b.product.unit.unitNumber || 0) - Number(a.product && a.product.unit && a.product.unit.unitNumber || 0)
						} else {
							return Number(b.product && b.product.lot && b.product.lot.name || 0) - Number(a.product && a.product.lot && a.product.lot.name || 0)
						}
					} else if (this.sortBy === 'status:ASC') {
						return a.status < b.status ? -1 : a.status > b.status ? 1 : 0
					} else if (this.sortBy === 'status:DESC') {
						return a.status < b.status ? 1 : a.status > b.status ? -1 : 0
					}
				})
			}

			return transactions
		},
		instance() {
			return this.$store.state.instance || {};
		},
		loadingPage: {
			get() {
				return this.$store.state.appData.loadingPage
			},
			set(val) {
				this.$store.commit('LOAD_PAGE', val)
			}
		},
		user() {
			return this.$store.state.user.user
		},

		sortOptions() {
			return [
				{ name: 'Newest First', value: 'createdAt:DESC' },
				{ name: 'Oldest First', value: 'createdAt:ASC' },
				{ name: 'Price: Low to High', value: 'price:ASC' },
				{ name: 'Price: High to Low', value: 'price:DESC' },
				{ name: `${this.instance.productType === 'lowrise' ? 'Lot' : 'Unit'} Order Ascending`, value: 'product:ASC' },
				{ name: `${this.instance.productType === 'lowrise' ? 'Lot' : 'Unit'} Order Descending`, value: 'product:DESC' },
				{ name: 'Status in Ascending', value: 'status:ASC' },
				{ name: 'Status in Descending', value: 'status:DESC' },
			]
		},

		rowSelection() {
            return {
                selectedRowKeys: this.selectedTransactions,
                onChange: this.onSelectChange,
            };
        },
	},
	methods: {
		formatDate,
		changeDisplay(item) {
			this.$store.commit('CHANGE_DISPLAY', item)
		},
		formatPrice(x) {
			return formatNumber(parseFloat(x))
		},
		classLookup(st) {
			let lookup = {
				void: 'badge-red',
				created: 'bg-black text-white',
				pending: 'bg-black text-white',
				completed: 'badge-green',
				draft: 'bg-gray text-white',
			}
			let textLookup = {
				void: 'Voided',
				created: 'Pending'
			}
			return {
				color: lookup[st] || 'badge',
				text: textLookup[st] || st,
			}
		},
		moreActions(transaction) {
			let actions = [{ label: transaction.status === 'void' ? 'View' : 'Edit', value: 'edit', icon: 'edit' }]
			if (this.editingRights(transaction)) {
				if (['void', 'completed'].includes(transaction.status)) {
					actions.push({ label: 'Archive', value: 'archive', icon: 'fa-archive' })
				}
				if (!['void', 'draft'].includes(transaction.status)) {
					actions.push({ label: 'Void Transaction', value: 'void', icon: 'close' })
				} else if (['draft'].includes(transaction.status)) {
					actions.push({ label: 'Delete', value: 'delete', icon: 'delete' })
				}
			}
			return actions
		},
		editingRights(transaction) {
			let canEdit = false
			if (this.$p >= 40) {
				return true
			} else {
				transaction.owners.forEach(x => {
					if (x.id == this.user.id) {
						canEdit = true
					}
				})
				return canEdit
			}
		},

		roleOptions(val, transaction) {
			let tr = transaction
			tr = JSON.parse(JSON.stringify(tr))
			let name = this.transactionName
			let asset = this.$store.state.instance.productType === 'lowrise' ? 'Siteplan Lot' : 'Condo Unit'
			if (val.key == 'edit') {
				this.onEdit(tr)
			} else if (val.key == 'archive') {
				if (this.$p < 40) return this.$message.error('You do not have permission to archive this transaction')
				this.archiveTransaction(val)
			} else if (val.key === 'sold') {

				tr.status = 'sold'
				this.$confirm({
					title: 'Mark Transaction as Sold',
					content: h => <div>Do you want to mark this transaction <b>({name})</b> as sold?<br />This will set the underlying <b>{asset}'s to <em class="text-red">Sold</em></b>.<p class="mt-2">Are you sure you want to continue?</p></div>,
					okText: 'Continue',
					okType: 'primary',
					cancelText: 'Cancel',
					centered: true,
					onOk: () => {
						this.$api.put(`/transactions/:instance/${tr.id}/sold`, { status: 'sold' }).then(({ data }) => {
							this.$store.commit('SET_PROP', { where: ['transactions', tr.id], what: data })
						}).catch((err) => {
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								this.$message.error(this.$err(err, 'Error occurred while updating the transaction. Please try again.'))
							}
						})
					},
					onCancel() {
						console.log('CANCEL')
					}
				})
			} else if (val.key === 'unsell') {
				tr.status = 'hold'
				this.$confirm({
					title: 'Unsell Transaction',
					content: h => <div>Do you want to unsell this transaction <b>({name})</b>? <br />This will set the underlying <b>{asset}'s status to <em>Hold</em></b>.<p class="mt-2">Are you sure you want to continue?</p></div>,
					okText: 'Continue',
					okType: 'primary',
					cancelText: 'Cancel',
					centered: true,
					onOk: () => {
						this.$api.put(`/transactions/:instance/${tr.id}/unsell`, { status: 'sold' }).then(({ data }) => {
							this.$store.commit('SET_PROP', { where: ['transactions', tr.id], what: data })
						}).catch((err) => {
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								this.$message.error(this.$err(err, 'Error occurred while updating the transaction. Please try again.'))
							}
						})
					},
					onCancel() {
						console.log('CANCEL')
					}
				})
			} else if (val.key === 'void') {

				if (this.$p < 40) return this.$message.error('You do not have permission to void this transaction')
				if (tr.isSigned || tr.offerType === 'conditional' || tr.status === 'completed') {
					let message = 'Cannot Void Conditional Transaction'
					let description = 'Consider creating a Notice of Mutual Release to void this transaction.'
					if (tr.isSigned) {
						message = 'Cannot Void Signed Transaction'
					}
					return this.$notification.error({
						message,
						description,
					})

				}

				val.key = 'voidConfirm'
				return this.roleOptions(val, tr)


			} else if (val.key === 'voidConfirm') {

				let isComplete = tr.status === 'completed' || tr.isSigned
				let obj = {
					title: 'Void Transaction',
					// content: h => <div>Do you want to void this transaction<b>({name})</b>? <br/>This will void any documents that were previously signed and remove any supporting documents and also removes any restrictions from the underlying <b>{asset}</b> and revert it's status to <em>Available</em>.<p class="mt-2">Are you sure you want to continue?</p></div>,
					content: h => <div>Voiding this transaction will remove all supporting documents and will revert the unit status back to available. </div>,
					okText: 'YES, VOID',
					okType: 'danger',
					cancelText: 'Cancel',
					centered: true,
					onOk: () => {
						this.$store.commit('LOAD', true)
						this.$api.put(`/transactions/:instance/${tr.id}/void`).then(({ data }) => {
							this.$store.commit('SET_PROP', { where: ['transactions', tr.id], what: data })
							this.$store.dispatch("FETCH_TRANSACTIONS");
						}).catch((err) => {
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								this.$message.error(this.$err(err, 'Error occurred while voiding the transaction. Please try again.'))
							}
						}).finally(() => this.$store.commit('LOAD', false))
					},
					onCancel() {
						console.log('CANCEL')
					}
				}
				if (isComplete) {
					obj.content = h => <div><p>Voiding this transaction will remove all supporting documents and will revert the unit status back to available.</p><p><strong>Remember to create an amendment to void the transaction before continuing.</strong></p> </div>
				}
				this.$confirm(obj)
			} else if (val.key === 'delete') {
				this.$confirm({
					title: 'Delete Draft Transaction',
					content: h => <div>Deleting this transaction will remove hold on lot/unit and will revert the lot/unit status back to available. </div>,
					okText: 'YES, DELETE',
					okType: 'danger',
					cancelText: 'Cancel',
					centered: true,
					onOk: () => {
						this.$store.commit('LOAD', true)
						this.$api.delete(`/transactions/:instance/${tr.id}/draft`).then(() => {
							this.$store.commit('SET_PROP', { where: ['transactions', tr.id], del: true })
							this.$store.dispatch("FETCH_TRANSACTIONS");
						}).catch((err) => {
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								this.$message.error(this.$err(err, 'Uh oh, something went wrong. Please try again or contact your admin'))
							}
						}).finally(() => this.$store.commit('LOAD', false))
					},
					onCancel() {
						console.log('CANCEL')
					}
				})
			}
		},

		onEdit(transaction) {
			if (transaction.status === 'draft') {
				this.$router.push(`/new?draft=${transaction.id}`)
			} else {
				this.$router.push(`/transaction/${transaction.id}`)
			}
		},

		transactionPurchasers(tr) {

			if (!tr.purchasers) return 'No Purchaser(s)'
			if (!tr.purchasers.length) return 'No Purchaser(s)'
			return tr.purchasers.length < 3 ? tr.purchasers.filter((x, xI) => xI < 2).map(x => `${x.firstName} ${x.lastName[0]}.`).join(', ') : `${tr.purchasers.length} Purchasers`
		},
		transactionName(tr) {

			if (this.instance.productType == 'highrise') {
				let { product = {}, purchasers = [] } = tr
				let { floor = {}, unit = {} } = product
				let name = floor.name && unit.unitNumber && `${floor.name} - ${unit.unitNumber}` || 'Floor N/A'

				if (purchasers.length === 1) name += ` - (Buyer: ${purchasers[0].fullName})`
				else name += ` - ${purchasers.length} Purchasers`

				return name
			} else {
				let { product = {}, purchasers = [] } = tr
				let { lot = {} } = product
				let name = `Lot ${lot.name || 'N/A'}`
				if (lot.address) {
					name += ` - ${lot.address}`
				} else {
					if (purchasers.length === 1) name += ` - (Buyer: ${purchasers[0].fullName})`
					else name += ` - ${purchasers.length} Purchasers`

				}
				return name
			}
		},
		editArchive(data) {
			let self = this
			this.$confirm({
				title: 'Edit Transaction',
				content: h => <div>Do you want to edit this transaction <b>{data.name}</b>? This transaction will be unarchived. Are you sure you want to continue?</div>,
				okText: 'Continue',
				okType: 'primary',
				cancelText: 'Cancel',
				centered: true,
				onOk: () => {
					this.$api.put(`/transactions/:instance/${data.id}`, { archived: false }).then(({ data }) => {
						this.$store.commit('SET_PROP', { where: ['transactions', data.id, 'archived'], what: false })
						if (data.status === 'draft') {
							this.$router.push(`/new?draft=${data.id}`)
						} else {
							this.$router.push(`/transaction/${data.id}`)
						}
					}).catch((err) => {
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							this.$message.error(this.$err(err))
						}
                    })
				},
				onCancel() {
					console.log('CANCEL')
				}
			});
		},
		confirmArchive() {
			let { notes } = this.selectedTransaction
			if (!notes) notes = []

			notes.push({
				subject: 'Archived on:  ' + formatDate(Date.now()),
				content: this.archiveNote,
				updatedAt: Date.now(),
				id: Date.now() + '',
				createdBy: '',
			})

			this.$api.put(`/transactions/:instance/${this.selectedTransaction.id}`, { archived: true, notes }).then(({ data }) => {
				this.$store.commit('SET_PROP', { where: ['transactions', data.id], what: data })
				this.onClose()
			}).catch((err) => {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
            })
		},
		editTransaction(trans) {
			console.log('EDIT TRANSACTION', trans)
		},
		archiveTransaction(trans) {
			this.showArchive = true
			this.selectedTransaction = trans.val
		},
		goToContract(item) {
			this.$router.push('/contract/' + item.id)
		},
		use(id) {
			this.templateId = id;
			this.selectContactView = true;
		},
		selectContact() {

			let formData = new FormData();
			formData.set("templateId", this.templateId);
			formData.set("customer", this.customer);

			this.$api
				.post(`/contract-templates/${this.instance.id}/${this.templateId}/`, formData)
				.then(({ data }) => {
					// this.onClose({ type: "add", data });
				}).catch((err) => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
                });
		},
		onClose() {
			this.showArchive = false
			this.selectedTransaction = {}
			this.archiveNote = ''
		},
		closeSelectContactView() {
			this.selectContactView = false;
			this.templateId = '';
		},
		newTransaction() {
			let mainTemplates = Object.values(this.$store.state.appData.templates).filter(x => {
				return x.type === 'purchase' || x.type === 'lease'
			})
			if (!mainTemplates.length) {
				return this.$message.error('Please setup Contract Templates before creating a Transaction. Please contact your project administrator.', 5)
			}

			this.$router.push('/new')
		},

		async onDownload(action) {
			if (action) {
				if (this.$p < 40) {
					this.bulkAction = 'Bulk Action'
					return this.$message.error('You do not have permission to download transaction files.')
				}

				if (action === 'selected' && this.selectedTransactions.length === 0) {
					this.bulkAction = 'Bulk Action'
					return this.$message.warn("Please select transactions first.");
				}

				try {
					let payload = {};

					if (action === 'selected') {
						payload.ids = this.selectedTransactions;
					} else if (action === 'all') {
						payload.all = true;
					} else if (action === 'archived') {
						payload.archived = true;
					} else {
						payload.statuses = [action];
					}
					const { data } = await this.$api.post(`transactions/:instance/bulk-download`, { ...payload })
					this.$message.success(data.message);
					this.selectedTransactions = [];
				} catch (err) {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err, "Error while downloading transaction files. please try again!"))
					}
				}
				this.bulkAction = 'Bulk Action'
			}
		},

		toggleSelect(id) {
			if (this.selectedTransactions.includes(id)) {
				let index = this.selectedTransactions.findIndex(x => x == id)
				this.selectedTransactions.splice(index, 1)
			}
			else {
				this.selectedTransactions.push(id)
			}
		},

		onSelectChange(selectedRowKeys) {
            this.selectedTransactions = selectedRowKeys;
        },

		getArchiveNote(transaction){
			let note = '';
			if(transaction.notes){
				note = transaction.notes.filter(n => n.content).map(n => {
					return `${n.content}`
				}).join(', ')
			}
			return note
		},

		async getTransactions(){
			this.loading = true;
			this.$nprogress.start();
			await this.$store.dispatch("FETCH_TRANSACTIONS");
			this.$nprogress.done();
			this.loading = false;
		},

		onShowSizeChange(current, pageSize) {
			this.currentPage = 1;
			this.pageSize = pageSize
		},

		handleChange(pagination, filter, sorter) {
            if (!sorter.order) this.sortItem = {}
            else if(sorter.columnKey) {
                this.sortItem = {
                    key:sorter.columnKey,
                    order:sorter.order
                }
            }

            if (this.sortItem && Object.keys(this.sortItem).length != 0) {
                let query = this.sortItem.key + ':' + (this.sortItem.order == 'descend' ? 'DESC' : 'ASC')
                this.$store.commit('EDIT_SORT_QUERY', query)
            } else this.$store.commit('EDIT_SORT_QUERY', '')
            this.getTransactions();
        },
	},
	created() {
		this.$store.commit('PAGE_BUTTONS', [
			{
				label: 'ADD NEW TRANSACTION',
				ant: 'plus',
				callback: this.newTransaction
			}
		])
	},
}
</script>
<style lang="scss" module>
@import "./style.module.scss";
</style>
<style lang="scss" scoped>
.contacts-page {
	@media screen and (min-width:567px) {
		margin-top: 5%;
	}
}

.aB {
	align-items: baseline;
}
</style>

<style scoped>
.edit-delete {
	cursor: pointer;
	color: rgba(0, 0, 0, 0.65);
	transition: color .1s ease-in;
}

.edit-delete:hover {
	color: orange;
	transition: color .1s ease-in;
}
</style>

<style lang="scss">
.owner-icon {
	border-radius: 50px;
	width: 50px;
	height: 50px;
	background-color: #F4F3F6;

	.inside {
		width: 50px;
		height: 50px;
		border-radius: 50px;
		background-repeat: no-repeat;
		background-size: contain;
		background-position: 50%;
	}
}

.archived-table {
	border: 1px solid #E4E7EB;
}

.archived-table .ant-table-thead>tr>th {
	background-color: white;
}

.archived-table .ant-table-tbody>tr {
	height: 150px;
}

.archived-table .ant-table-tbody>tr>td {
	background-color: white;
}

.archived-table .ant-table-footer {
	background-color: white;
}
</style>
